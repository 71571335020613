import React, { useState, useEffect } from "react";
import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, InputLabel, TextField, Select, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { getDatabase, ref, set, get } from "firebase/database";
import firebaseApp, { KEY, KEY_SITE } from '../../integration/FirebaseConfig';
import { Helmet } from "react-helmet";
import * as MuiIcons from '@mui/icons-material';

const database = getDatabase(firebaseApp);

const Config = () => {
    const [isGradient, setIsGradient] = useState(false);
    const [color1, setColor1] = useState('#ffffff');
    const [color2, setColor2] = useState('#ffffff');
    const [imageFile, setImageFile] = useState(null);
    const [imagePath, setImagePath] = useState('');
    const [newImagePreview, setNewImagePreview] = useState(null);

    const [menuName, setMenuName] = useState('');
    const [menuLink, setMenuLink] = useState('');
    const [menuIcon, setMenuIcon] = useState('');
    const [menuActive, setMenuActive] = useState(false);

    const primaryIcons = [
        'Home',
        'Info',
        'ContactMail',
        'MenuBook',
        'AccountCircle',
        'Settings',
        'Help',
        'Description'
    ];

    useEffect(() => {
        const fetchData = async () => {
            const dbRef = ref(database, `db/cidades/${KEY}/config/`);
            const snapshot = await get(dbRef);
            if (snapshot.exists()) {
                const data = snapshot.val();
                if (data.background) {
                    const colors = data.background.match(/#[0-9a-fA-F]{6}/g);
                    if (colors) {
                        setColor1(colors[0]);
                        setColor2(colors[1] || colors[0]);
                        setIsGradient(colors.length > 1);
                    }
                }
                if (data.banner) {
                    setImagePath(data.banner);
                }
                if (data.destaque) {
                    setMenuName(data.destaque.name);
                    setMenuLink(data.destaque.link);
                    setMenuIcon(data.destaque.icon);
                    setMenuActive(data.destaque.active);
                }
            }
        };
        fetchData(); 
    }, []);

    const handleSave = async () => {
        const gradientValue = isGradient
            ? `linear-gradient(to bottom, ${color1}, ${color2})`
            : `linear-gradient(to bottom, ${color1}, ${color1})`;

        let bannerPath = imagePath;
        if (imageFile) {
            const formData = new FormData();
            formData.append('file', imageFile);
            const response = await fetch(`${KEY_SITE}update_banner.php`, {
                method: 'POST',
                body: formData,
            });
            const result = await response.text();
            bannerPath = result;
        }

        await set(ref(database, `db/cidades/${KEY}/config/`), {
            background: gradientValue,
            banner: bannerPath,
            destaque: {
                name: menuName,
                link: `${menuLink}`,
                icon: menuIcon,
                active: menuActive
            }
        });

        window.location.reload();
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImageFile(file);

        const reader = new FileReader();
        reader.onloadend = () => {
            setNewImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handleRestore = () => {
        setColor1('#096cff');
        setColor2('#3498DB');
        setIsGradient(true);
    };

    return (
        <>
            <Helmet>
                <title>Painel - Configurações</title>
            </Helmet>

            <Box m="20px">
                <Box mb="30px" display="flex" alignItems="center" backgroundColor="#1d1d1d" padding={2} borderRadius={3} >
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Divider sx={{ color: "#0F71FD", mb: 2, borderRadius: "25px", backgroundColor: "transparent", padding: "20px", fontWeight: 'bold', fontSize: '22px' }} textAlign="CENTER">
                                EDITAR CORES DO SITE
                            </Divider>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isGradient}
                                        onChange={(e) => setIsGradient(e.target.checked)}
                                    />
                                }
                                label="DUAS CORES? (GRADIENT)"
                            />
                            <TextField
                                type="color"
                                label="Color 1"
                                value={color1}
                                onChange={(e) => setColor1(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            {isGradient && (
                                <TextField
                                    type="color"
                                    label="Color 2"
                                    value={color2}
                                    onChange={(e) => setColor2(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                            )}
                            <Button variant="contained" color="secondary" onClick={handleRestore} style={{ marginTop: '10px' }}>
                                Restaurar
                            </Button>
                        </Grid>

                        <Grid item xs={6}>
                            <Divider sx={{ color: "#0F71FD", mb: 2, borderRadius: "25px", backgroundColor: "transparent", padding: "20px", fontWeight: 'bold', fontSize: '22px' }} textAlign="CENTER">
                                BANNER DESTAQUE DO SITE
                            </Divider>
                            <InputLabel htmlFor="banner-upload">Selecione uma imagem (JPG, PNG, JPEG)</InputLabel>
                            <input
                                type="file"
                                accept=".jpg, .jpeg, .png"
                                id="banner-upload"
                                onChange={handleImageChange}
                                style={{ display: 'block', margin: '10px 0' }}
                            />
                            {imagePath && (
                                <div>
                                    <p>Banner atual:</p>
                                    <img src={imagePath} alt="Banner Atual" style={{ width: '100%', marginBottom: '10px' }} />
                                </div>
                            )}
                            {newImagePreview && (
                                <div>
                                    <p>Nova imagem selecionada:</p>
                                    <img src={newImagePreview} alt="Nova Imagem" style={{ width: '100%', marginBottom: '10px' }} />
                                </div>
                            )}
                        </Grid>

                        <Grid item xs={6}>
                            <Divider sx={{ color: "#0F71FD", mb: 2, borderRadius: "25px", backgroundColor: "transparent", padding: "20px", fontWeight: 'bold', fontSize: '22px' }} textAlign="CENTER">
                                GUIA/MENU EM DESTAQUE
                            </Divider>
                            <TextField
                                label="Nome do Destaque"
                                value={menuName}
                                onChange={(e) => setMenuName(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Link do Destaque"
                                value={menuLink}
                                onChange={(e) => setMenuLink(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <InputLabel htmlFor="icon-select">Ícone do Destaque</InputLabel>
                            <Select
                                labelId="icon-select"
                                value={menuIcon}
                                onChange={(e) => setMenuIcon(e.target.value)}
                                fullWidth
                                margin="normal"
                            >
                                {primaryIcons.map((icon) => {
                                    const IconComponent = MuiIcons[icon];
                                    return (
                                        <MenuItem key={icon} value={icon}>
                                            <ListItemIcon>
                                                <IconComponent />
                                            </ListItemIcon>
                                            <ListItemText primary={icon} />
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={menuActive}
                                        onChange={(e) => setMenuActive(e.target.checked)}
                                    />
                                }
                                label="Ativo"
                            />
                        </Grid>
                      
                    </Grid>

                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Salvar
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default Config;
